import React from 'react';
import { FaDiscord, FaLinkedin, FaGithub, FaFacebook } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { SiCashapp, SiMedium } from 'react-icons/si';
import { IoLogoVenmo } from 'react-icons/io5';

function ContactMe() {
  return (
    <div className="px-5 max-w-[1560px] min-h-screen mx-auto pt-20 py-10">
      {/* title */}
      <div className=" text-white font-semibold text-[32px] mt-12">
        <span className="text-[#c778dd]">/</span>contacts
      </div>
      {/* h2 title */}
      <div className=" text-white mt-[14px] mb-[46px]">Get in touch!</div>
      {/* flex main */}
      <div className=" flex justify-between flex-wrap gap-10 pb-10 ">
        {/* left */}
        <div className="  md:w-3/5 w-full">
          {/* text area */}
          <div className=" text-[#ABB2BF] font-medium">
            I'm interested in full-time employment opportunities. However, if
            you have other requests or questions, don't hesitate to contact me.
          </div>
        </div>
        {/* right */}
        <div className=" flex flex-wrap gap-3">
          {/* boxes/flex */}
          <div className="border border-[#ABB2BF] p-4">
            <h2 className="  text-white font-medium underline pb-4">
              Message me here
            </h2>
            <div className="">
              {/* links */}
              <div className="flex gap-1 items-center">
                <MdEmail color="#C778DD" />
                <span className="text-[#ABB2BF]">
                  &nbsp;chididokpara@gmail.com
                </span>
              </div>
              <p style={{ lineHeight: 1 }}>
                <br />
              </p>
              <div className="flex gap-1 items-center">
                <FaDiscord color="#C778DD" />
                <span className="text-[#ABB2BF]">&nbsp;chidoskii</span>
              </div>
            </div>
          </div>
          <div className="border border-[#ABB2BF] p-4">
            <h2 className=" text-white font-semibold underline pb-2">
              Support
            </h2>
            {/* get in touch */}
            <div className="">
              {/* links */}
              <a
                href="https://cash.app/$chidoskii"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex gap-1 items-center">
                  <SiCashapp color="#C778DD" />
                  <span className="text-[#ABB2BF]">&nbsp;$chidoskii</span>
                </div>
              </a>
              <p style={{ lineHeight: 1 }}>
                <br />
              </p>
              <a
                href="https://venmo.com/u/chidoskii"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex gap-1 items-center">
                  <IoLogoVenmo color="#C778DD" />
                  <span className="text-[#ABB2BF]">&nbsp;@chidoskii</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* all social media links */}
      <div className=" mt-6 mb-5x ">
        {/* title */}
        <div className="text-[32px] font-medium text-white">
          <span className="text-[#c778dd]">#</span>all-socials
        </div>
        {/* media links */}
        <div className="flex flex-wrap gap-6 pt-5">
          {/* links */}
          <div className="flex gap-1 items-center">
            <a
              title="linkedin profile"
              href="https://www.linkedin.com/in/chidi-okpara/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin color="#C778DD" />
            </a>
            <span className="text-[#ABB2BF]">&nbsp;Chidi Okpara</span>
          </div>
          <p style={{ lineHeight: 1 }}>
            <br />
          </p>
          <div className="flex gap-1 items-center">
            <a
              title="github profile"
              href="https://github.com/chidoskii"
              target="_blank"
              rel="noreferrer"
            >
              <FaGithub color="#C778DD" />
            </a>
            <span className="text-[#ABB2BF]">&nbsp;chidoskii</span>
          </div>
          <p style={{ lineHeight: 1 }}>
            <br />
          </p>
          <div className="flex gap-1 items-center">
            <a
              title="facebook profile"
              href="https://www.facebook.com/chidi.okpara.319"
              target="_blank"
              rel="noreferrer"
            >
              <FaFacebook color="#C778DD" />
            </a>
            <span className="text-[#ABB2BF]">&nbsp;Chidi Okpara</span>
          </div>
          <p style={{ lineHeight: 1 }}>
            <br />
          </p>
          <div className="flex gap-1 items-center">
            <a
              title="github profile"
              href="https://medium.com/@chidoskii"
              target="_blank"
              rel="noreferrer"
            >
              <SiMedium color="#C778DD" />
            </a>
            <span className="text-[#ABB2BF]">&nbsp;chidoskii</span>
          </div>
          <p style={{ lineHeight: 1 }}>
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactMe;
