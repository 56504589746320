import React from 'react';
import first from './img/doc-pat.jpg';
import second from './img/pirate-ub.jpg';
import third from './img/movbuff.jpg';
import fourth from './img/UFC.jpg';
import fifth from './img/task-track.jpeg';
import sixth from './img/cov.jpg';
import ProjectCard from '../project_card/ProjectCard';
import { SiMariadb, SiMongodb, SiExpress } from 'react-icons/si';
import {
  FaHtml5,
  FaCss3Alt,
  FaJsSquare,
  FaNode,
  FaPhp,
  FaReact,
} from 'react-icons/fa';

const Complete = () => {
  const projects = [
    {
      img: first,
      langs: [
        <FaHtml5 />,
        <FaCss3Alt />,
        <FaJsSquare />,
        <SiMongodb />,
        <SiExpress />,
        <FaReact />,
        <FaNode />,
      ],
      title: 'RunnerHealth™',
      subtitle: 'Senior Project 2023 - 2024',
      desc: 'An Electronic Health Record management applicationm that improves the quality of healthcare for patients.',
      webpage: 'https://runnerhealth.xyz/',
      scode: 'https://github.com/Chidoskii/HealthApp-UI',
      demo: 'https://www.youtube.com/channel/UCzZSR2i9nK1OrxAcQ9xv9CQ',
    },
    {
      img: second,
      langs: [
        <FaHtml5 />,
        <FaCss3Alt />,
        <FaJsSquare />,
        <SiMariadb />,
        <FaPhp />,
      ],
      title: 'Usher Busser™',
      subtitle: 'Web Programming II - Spring 2024',
      desc: 'Movie theatre app for theatre employees and film aficionados.',
      webpage: 'https://usherbusser.xyz/',
      scode: 'https://github.com/Chidoskii/UsherBusser',
      demo: 'https://www.youtube.com/channel/UCzZSR2i9nK1OrxAcQ9xv9CQ',
    },
    {
      img: third,
      langs: [
        <FaHtml5 />,
        <FaCss3Alt />,
        <FaJsSquare />,
        <SiMariadb />,
        <FaPhp />,
      ],
      title: 'The Movie Buffs',
      subtitle: 'Software Engineering - Spring 2024',
      desc: 'A web app to rate and review films.',
      webpage: 'https://artemis.cs.csub.edu/~cokpara/MovieBuffs/',
      scode: 'https://github.com/Chidoskii/TheMovieBuffs',
      demo: 'https://www.youtube.com/watch?v=BtntLwrGN-I',
    },
    {
      img: fourth,
      langs: [
        <FaHtml5 />,
        <FaCss3Alt />,
        <FaJsSquare />,
        <SiMariadb />,
        <FaPhp />,
      ],
      title: 'UFC Database',
      subtitle: 'Database Systems - Fall 2022',
      desc: 'Information on UFC fighters can be searched using this application. *********************************\n userEmail: mboysej@wordpress.org ||\n userPassword: s3syHoQ ||\n employeeEmail: ahealey2@desdev.cn ||\n employeePassword: 15OYZkAmrv5 ||\n *********************************',
      webpage: 'https://artemis.cs.csub.edu/~cokpara/ufcdb/login.php',
      scode: 'https://github.com/Chidoskii/UFCdb',
      demo: 'https://youtu.be/Mt76JZTjJ1Y',
    },
    {
      img: fifth,
      langs: [
        <FaHtml5 />,
        <FaCss3Alt />,
        <FaJsSquare />,
        <SiMongodb />,
        <SiExpress />,
        <FaReact />,
        <FaNode />,
      ],
      title: 'Task Tracker',
      subtitle: 'App Development - Spring 2023',
      desc: 'Log issues and track the progress. NOTE: The server may take a while initially to spin up. *********************************  userEmail: mario@gmail.com, luigi@gmail.com, daisy@gmail.com || password: ABCabc123! *********************************',
      webpage: 'https://slvnt.onrender.com/',
      scode: 'https://github.com/Chidoskii/Task-app',
      demo: 'https://youtu.be/7JBuNpoIatA',
    },
    {
      img: sixth,
      langs: [
        <FaHtml5 />,
        <FaCss3Alt />,
        <FaJsSquare />,
        <SiMongodb />,
        <SiExpress />,
        <FaReact />,
        <FaNode />,
      ],
      title: 'Covada',
      subtitle: 'Personal Project - Under Development',
      desc: 'One stop shop for sports enthusiast. Sports odds, news, stats, and a thriving community. This projects is still in the works, stay tuned!',
      webpage: 'https://covada.us/',
      scode: 'https://github.com/Chidoskii',
      demo: 'https://www.youtube.com/channel/UCzZSR2i9nK1OrxAcQ9xv9CQ',
    },
  ];

  return (
    <div>
      <div className="min-h-screen pt-[114px] px-5 max-w-[1560px] mx-auto">
        {/* top / title */}
        <div className="">
          {/* Projects */}
          <div className="text-white font-bold text-[32px]">
            <span className="text-[#C778DD]">/</span>
            <span>projects</span>
          </div>
          {/* list */}
          <div className="text-white mt-[14px]">List of my projects</div>
        </div>
        {/* completed projects */}
        <div className="">
          {/* title */}
          <div className="text-white font-medium mt-[68px] mb-[48px] text-[32px]">
            <span className="text-[#C778DD]">#</span>
            <span>
              full<span className="text-[#C778DD]">-</span>stack-projects
            </span>
          </div>
          {/*projects mapped */}
          <div className="flex flex-wrap justify-start gap-4 my-12">
            {projects.map(
              ({ img, langs, title, subtitle, desc, webpage, scode, demo }) => {
                return (
                  <>
                    <ProjectCard
                      img={img}
                      langs={langs}
                      title={title}
                      subtitle={subtitle}
                      desc={desc}
                      webpage={webpage}
                      scode={scode}
                      demo={demo}
                    />
                  </>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Complete;
