import { useEffect, useMemo } from "react";
import Footer from "./components/footer/Footer";
import { IconContext } from "react-icons/lib";
import { Route, Routes } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import HomePage from "./pages/HomePage";
import ProjectsPage from "./pages/ProjectsPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
// Animation on Scroll
import Aos from "aos";
import "aos/dist/aos.css";

function App() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  }, []);

  const foo = useMemo(() => ({ size: "2em" }), []);
  return (
    <IconContext.Provider value={foo}>
      <div className="bg-[#282C33]">
        <Navbar />
        <Routes>
          {/* HomePage*/}
          <Route path="/" element={<HomePage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
        <Footer />
      </div>
    </IconContext.Provider>
  );
}

export default App;
