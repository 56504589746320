import React from "react";

function Facts() {
  return (
    <div className="px-5 max-w-[1560px] mx-auto py-10">
      {/* Title */}
      <div className="mb-12 text-[32px] font-medium text-white">
        <span className="text-[#C778DD]">#</span>fun-facts
      </div>
      <div className="flex justify-center gap-y-10 items-center flex-wrap">
        {/* left */}
        <div className="flex justify-start gap-4 w-full md:w-[50%] flex-wrap">
          <div className="fact border border-[#ABB2BF] p-2 text-[#ABB2BF] text-base font-normal">
            My favorite colors are black, blue, and green
          </div>
          <div className="fact border border-[#ABB2BF] p-2 text-[#ABB2BF] text-base font-normal">
            Fortran and Ada are dreadful
          </div>
          <div className="fact border border-[#ABB2BF] p-2 text-[#ABB2BF] text-base font-normal">
            I love the Peaky Blinders
          </div>
          <div className="fact border border-[#ABB2BF] p-2 text-[#ABB2BF] text-base font-normal">
            Wrote first line of code in 2019
          </div>
          <div className="fact border border-[#ABB2BF] p-2 text-[#ABB2BF] text-base font-normal">
            Basketball is my favorite sport
          </div>
          <div className="fact border border-[#ABB2BF] p-2 text-[#ABB2BF] text-base font-normal">
            Android {">"} Apple
          </div>
          <div className="fact border border-[#ABB2BF] p-2 text-[#ABB2BF] text-base font-normal">
            Avatar Aang is my role model
          </div>
          <div className="fact border border-[#ABB2BF] p-2 text-[#ABB2BF] text-base font-normal">
            I build high quality PCs
          </div>
          <div className="fact border border-[#ABB2BF] p-2 text-[#ABB2BF] text-base font-normal">
            I strum a guitar from time to time
          </div>
          <div className="fact border border-[#ABB2BF] p-2 text-[#ABB2BF] text-base font-normal">
            C++ and Python are the first languages I learned
          </div>
        </div>
        {/* right */}
        <div className="flex justify-center items-center w-full md:w-[50%]">
          <img className="w-[300px]" src={require("./img/shapes.png")} alt="" />
        </div>
      </div>
    </div>
  );
}

export default Facts;
