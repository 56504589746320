import './about.css';
import { NavLink } from 'react-router-dom';
import { MdOutlineConstruction } from 'react-icons/md';
import { FaYoutube, FaLinkedin, FaGithub } from 'react-icons/fa';

const About = () => {
  return (
    <>
      <div className="px-5 max-w-[1560px] mx-auto min-h-screen pt-20 flex items-center justify-between flex-wrap">
        <div data-aos="fade-down" className="w-10/12 sm:w-8/12 mx-auto">
          <h1 className="font-semibold text-[32px] text-white mb-3">
            Hi, my name is Chidi Okpara and I am a{' '}
            <span className="text-[#C778DD]">Computer Scientist</span>.
            {/*front-end developer, sports fanatic, software engineer, film enthusiast, IT support technician, music lover*/}
          </h1>
          <p className="text-[#ABB2BF] my-6">
            I am interested in application development, cyber security, data
            analytics, and bioinformatics.
          </p>
          <NavLink to="/contact">
            <button className="text-white font-medium py-2 px-4 border duration-200 border-[#C778DD] hover:bg-[#C778DD33] ">
              Contact me!
            </button>
          </NavLink>
        </div>
        <div data-aos="fade-up" data-aos-delay="400" className="mx-auto">
          <div className="flex justify-center">
            <img src={require('./img/idphoto.png')} alt="" />
          </div>
          <div className="border flex items-center gap-2 border-[#ABB2BF] p-2 text-[#ABB2BF]">
            <div className="w-4 h-4 bg-[#C778DD]"></div>
            <div className="">
              <div className="ml-20 flex items-center gap-10">
                <a
                  title="LinkedIn"
                  href="https://www.linkedin.com/in/chidi-okpara/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin color="#ABB2BF" />
                </a>
                <a
                  title="GitHub"
                  href="https://www.github.com/chidoskii"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub color="#ABB2BF" />
                </a>
                <a
                  title="YouTube"
                  href="https://www.youtube.com/@chidoskiitech"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube color="#ABB2BF" />
                </a>
              </div>
            </div>
          </div>
          <div className="border flex items-center gap-4 border-[#ABB2BF] p-2 text-[15px] text-[#ABB2BF]">
            ** Portfolio is under construction{' '}
            <MdOutlineConstruction size={'1.5em'} />
          </div>
        </div>
      </div>
      <div data-aos="fade-up" className="px-5 py-10">
        <img
          className="mx-auto"
          src={require('./img/rum.png')}
          alt=""
          srcSet=""
        />
      </div>
    </>
  );
};

export default About;
