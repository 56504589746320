import { FaYoutube, FaLinkedin, FaGithub } from 'react-icons/fa';

const Footer = () => {
  return (
    <>
      {/* line */}
      <div className="w-full h-px bg-[#ABB2BF] mt-20 mb-8"></div>
      {/* footer */}
      <div className="px-5 max-w-[1560px] mx-auto">
        {/* top */}
        <div className="flex justify-between gap-y-10 flex-wrap">
          {/* left */}
          <div className="mx-auto md:mx-0">
            <div className=" flex items-center gap-8 mb-3 ">
              {/* logo */}
              <div className=" flex gap-2 items-center text-2xl text-white font-bold">
                <img src={require('./img/co3.png')} alt="" />
                <span>Chidi</span>
              </div>
              {/* email */}
              <span className="text-[#ABB2BF]">chididokpara@gmail.com</span>
            </div>
            {/* disc */}
            <div className="text-[#ffffff]">
              <p>Computer Scientist based in California, USA</p>
            </div>
            <p style={{ lineHeight: 1 }}>
              <br />
              <br />
              <br />
            </p>
            <div className="flex gap-2 items-center text-[10px] text-[#ABB2BF]">
              <span>page layout designed by </span>
              <a
                className="flex gap-2 items-center text-[#ABB2BF]"
                href="https://www.youtube.com/@beha-coder"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube size="1em" color="#FF0000" />
                <span>Beha Coder</span>
              </a>
            </div>
          </div>
          {/* right */}
          <div className=" mx-auto md:mx-0">
            {/* title */}
            <h2 className=" text-white text-2xl font-medium mb-3">Socials</h2>
            {/* media */}
            <div className="flex items-center gap-2">
              <a
                title="LinkedIn"
                href="https://www.linkedin.com/in/chidi-okpara/"
                target="_blank"
                rel="noreferrer"
              >
                <FaLinkedin color="#ABB2BF" />
              </a>
              <a
                title="GitHub"
                href="https://www.github.com/chidoskii"
                target="_blank"
                rel="noreferrer"
              >
                <FaGithub color="#ABB2BF" />
              </a>
              <a
                title="YouTube"
                href="https://www.youtube.com/@chidoskiitech"
                target="_blank"
                rel="noreferrer"
              >
                <FaYoutube color="#ABB2BF" />
              </a>
            </div>
          </div>
        </div>
        {/* bottom */}
        <div className="text-center text-[#ABB2BF] pb-3 mt-3">
          © Copyright 2023
        </div>
      </div>
    </>
  );
};

export default Footer;
