import Complete from "../components/complete_apps/Complete";
import SmallProjects from "../components/small_projects/SmallProjects";
import { FaArrowUp } from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";

const ProjectsPage = () => {
  return (
    <>
      <ScrollToTop
        id="scroll-to-top"
        title="back to top"
        smooth
        top={950}
        component={<FaArrowUp id="arrow" size={"1.5em"} />}
      />
      <Complete />
      <SmallProjects />
    </>
  );
};

export default ProjectsPage;
