const ProjectCard = ({
  img,
  langs,
  title,
  subtitle,
  desc,
  webpage,
  scode,
  demo,
}) => {
  return (
    <>
      <div
        data-aos="fade-up"
        data-aos-delay="200"
        className="border mx-auto w-[90%] md:w-[48%] lg:w-[30%] border-[#ABB2BF] p-0"
      >
        {/* img */}
        <div className="">
          <img className="h-auto md:h-72 lg:h-72 w-full" src={img} alt="" />
        </div>
        {/* skills */}
        <div className="flex gap-3 flex-wrap p-2 border-y border-[#ABB2BF] justify-evenly">
          {langs.map((e) => {
            return <span className=" text-[#ABB2BF]">{e}</span>;
          })}
        </div>
        {/* desc */}
        <div className="flex flex-col ">
          <div className="p-4">
            <h2 className="text-[#FFFFFF] text-2xl font-medium">{title}</h2>
            <h2 className="text-[#FFFFFF] text-base md:text-lg font-medium py-1">
              {subtitle}
            </h2>
            <p className="py-4 text-[#ABB2BF]">{desc}</p>
          </div>
          {/* btns */}
          <div className="relative inset-x-0 bottom-0 inline-grid grid-cols-3 gap-2 px-2 py-6 justify-items-center">
            <a href={webpage} target="_blank" rel="noreferrer">
              <button className=" py-2 px-4 text-white border border-[#C778DD] hover:bg-[#C778DD33] duration-150">
                Site {'<'}~{'>'}
              </button>
            </a>
            <a href={scode} target="_blank" rel="noreferrer">
              <button className=" py-2 px-4 text-white border border-[#C778DD] hover:bg-[#C778DD33] duration-150">
                Code {'<'}~{'>'}
              </button>
            </a>
            <a href={demo} target="_blank" rel="noreferrer">
              <button className=" py-2 px-4 text-white border border-[#C778DD] hover:bg-[#C778DD33] duration-150">
                Demo {'<'}~{'>'}
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectCard;
