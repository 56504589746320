const Skills = () => {
  const skills = [
    {
      title: "Languages",
      skills: [
        "JavaScript",
        "Java",
        "PHP",
        "SQL",
        "Ruby",
        "Python",
        "C#",
        "C++",
      ],
    },
    {
      title: "General",
      skills: [
        "Excellent communication",
        "Detail-oriented",
        "Leadership",
        "Self-driven",
      ],
    },
    {
      title: "Technical",
      skills: [
        "HTML/CSS",
        "Cloud computing",
        "Database management",
        "Troubleshooting",
        "Debugging",
        "RegEx",
      ],
    },
    {
      title: "Certificates",
      skills: ["Google IT Support Professional"],
    },
    {
      title: "Frameworks",
      skills: [
        "React",
        "Vue.js",
        "Angular",
        "Express",
        "Node.js",
        "ASP.NET",
        "Flask",
        "Django",
      ],
    },
    {
      title: "Tools",
      skills: ["VS-Code", "GitHub", "Render", "AWS", "Android Studio"],
    },
  ];
  return (
    <>
      <div className="px-5 max-w-[1560px] mx-auto py-10">
        {/* top */}
        <div data-aos="fade-up" className="mb-10">
          <div className="text-white w-2/3 font-medium text-[32px] flex items-center gap-2">
            <div className="">
              <span className="text-[#C778DD]">#</span>skills
            </div>
            <div className="line w-1/3 h-px bg-[#C778DD]"></div>
          </div>
        </div>
        {/* bottom */}
        <div className="flex flex-wrap gap-10 justify-between items-center text-white">
          {/* left */}
          <div data-aos="fade-up" className="mx-auto">
            <img
              className="w-full mx-auto"
              src={require("./img/shapes.png")}
              alt=""
            />
          </div>
          {/* right and mapping */}
          <div
            data-aos="fade-up"
            className="mx-auto flex justtify-around md:justtify-end flex-wrap w-10/12 md:w-1/2 gap-4"
          >
            {skills.map(({ title, skills }) => {
              return (
                <>
                  <div className=" w-[90%] sm:w-[45%] lg:w-[30%] border border-[#ABB2BF]">
                    <div className="p-2">
                      <h2 className="font-semibold">{title}</h2>
                    </div>
                    <div className=" flex gap-3 border-t border-[#ABB2BF] flex-wrap p-2 text-[#ABB2BF]">
                      {skills.map((e) => {
                        return <span>{e}</span>;
                      })}
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Skills;
