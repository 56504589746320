import React from "react";

function AllAbout() {
  return (
    <div className=" px-5 max-w-[1560px] mx-auto pt-20 py-10">
      {/* title */}
      <div className=" mb-12">
        <div className=" text-white w-2/3 font-semibold text-[32px]">
          <div className="">
            <span className="text-[#C778DD]">/</span>about-me
          </div>
          <div className="text-white text-base font-normal mt-[14px]">
            Get to know me!
          </div>
        </div>
      </div>
      <div className=" flex-wrap flex items-center justify-between">
        {/* left */}
        <div className="md:w-[48%] w-full">
          {/* disc */}
          <p className="text-[#ABB2BF] ">
            Hello, I'm Chidi Okpara!
            <br />
            <br />
            I am a goal driven, highly motivated frontend developer that is
            constantly learning new techniques to further improve my skillset.
            My interest in software and web development stems from my deep
            passion for sports. With all the advances made in modern technology,
            data collection in sports has never been more valuable. From
            determining the probability of where a baseball will land after
            being hit by a batter, to real-time data analysis during sports
            coverage; technology is changing the way sports are watched, played,
            and managed.
            <br />
            <br />
            Analyzing sports data is what initially led me to pursue a career in
            development, as my first idea was to build an app that can
            accurately predict the winner of a match. Of course, at the time I
            had no idea how to build an app and I was oblivious to the vast
            amount of work that went into developing and maintaining one. So, I
            figured it would be best to first obtain a degree in Computer
            Science and expand my technical knowledge.
          </p>
        </div>
        {/* right */}
        <div className=" mx-auto">
          <img className="mx-auto" src={require("./img/yup.png")} alt="" />
        </div>
      </div>
    </div>
  );
}

export default AllAbout;
