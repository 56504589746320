import ContactMe from "../components/contact_page/ContactMe";

const ContactPage = () => {
  return (
    <>
      <ContactMe />
    </>
  );
};
export default ContactPage;
