import { FaDiscord } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

const Contact = () => {
  return (
    <>
      <div className="px-5 max-w-[1560px] mx-auto mt-20 py-10">
        {/* top */}
        <div data-aos="fade-up" data-aos-delay="100" className="">
          {/* header */}
          <div className=" mb-10">
            <div className=" text-white w-2/3 font-medium text-[32px] flex items-center gap-2">
              <div className="">
                <span className="text-[#C778DD]">#</span>contacts
              </div>
              <div className="line w-1/3 h-px bg-[#C778DD]"></div>
            </div>
          </div>
        </div>
        {/* bottom */}
        <div className=" flex items-start flex-wrap gap-10 justify-between">
          {/* left */}
          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="left w-full md:w-1/2"
          >
            <p className=" text-[#ABB2BF]">
              I'm interested in full-time employment opportunities. However, if
              you have other requests or questions, don't hesitate to contact
              me.
            </p>
          </div>
          {/* right */}
          <div data-aos="fade-up" data-aos-delay="200" className=" mx-auto">
            {/* box */}
            <div className="border border-[#ABB2BF] p-4">
              <h2 className=" mb-4 text-white font-semibold underline">
                Message me here
              </h2>
              {/* get in touch */}
              <div className="">
                {/* links */}
                <div className="flex gap-1 items-center">
                  <MdEmail color="#C778DD" />
                  <span className="text-[#ABB2BF]">
                    &nbsp;chididokpara@gmail.com
                  </span>
                </div>
                <p style={{ lineHeight: 1 }}>
                  <br />
                </p>
                <div className="flex gap-1 items-center">
                  <FaDiscord color="#C778DD" />
                  <span className="text-[#ABB2BF]">&nbsp;chidoskii</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
