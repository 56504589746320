import AllAbout from "../components/about_page_section/AllAbout";
import Facts from "../components/facts_section/Facts";
import AboutSkills from "../components/skills_about_page/AboutSkills";
import Uni from "../components/education/Uni";
import { FaArrowUp } from "react-icons/fa";
import ScrollToTop from "react-scroll-to-top";

const AboutPage = () => {
  return (
    <>
      <ScrollToTop
        id="scroll-to-top"
        title="back to top"
        smooth
        top={750}
        component={<FaArrowUp id="arrow" size={"1.5em"} />}
      />

      <AllAbout />
      <Uni />
      <AboutSkills />
      <Facts />
    </>
  );
};
export default AboutPage;
