import React from 'react';

const SmallProjects = () => {
  //dates
  let projects = [
    {
      languages: ['JavaScript', 'HTML/CSS'],
      title: 'Web Dev',
      body: 'A collection of websites, portfolios, and mockups.',
      webpage: 'https://chidoskii.com/pages/webdev.html',
      scode: 'https://github.com/Chidoskii/WebDev',
      demo: 'https://chidoskii.com/pages/webdev.html',
    },
    {
      languages: ['JavaScript', 'HTML/CSS', 'MySQL', 'PHP'],
      title: 'eRowdy',
      body: 'An e-commerce web application. {email: "jg@smail.com", password: "ABCabc123!"}',
      webpage: 'https://cs.csub.edu/~cokpara/3350/eRowdy/pages/login.php',
      scode: 'https://github.com/Chidoskii/eCommerce',
      demo: 'https://www.youtube.com/channel/UCzZSR2i9nK1OrxAcQ9xv9CQ',
    },
    {
      languages: ['JavaScript', 'Python', 'HTML/CSS'],
      title: 'ASAVA: Another Sorting Visualition Application',
      body: 'Visual demonstrations of various sorting algorithms.',
      webpage: 'https://google.com/',
      scode: 'https://github.com/Chidoskii/',
      demo: 'https://www.youtube.com/channel/UCzZSR2i9nK1OrxAcQ9xv9CQ',
    },
    {
      languages: ['React', 'JavaScript', 'HTML/CSS'],
      title: 'Tic-Tac-Toe',
      body: 'A tic tac toe game made with react.',
      webpage: 'https://tictactoe-jkdb.onrender.com',
      scode: 'https://github.com/Chidoskii/tictactoe',
      demo: 'https://www.youtube.com/channel/UCzZSR2i9nK1OrxAcQ9xv9CQ',
    },
    {
      languages: ['JavaScript', 'HTML/CSS'],
      title: 'Kai Bai Bo',
      body: 'A simple Rock, Paper, Scissors game.',
      webpage: 'https://chidoskii.com/sites/KBB/kbb.html',
      scode: 'https://github.com/Chidoskii/WebDev/tree/main/KBB',
      demo: 'https://www.youtube.com/channel/UCzZSR2i9nK1OrxAcQ9xv9CQ',
    },
  ];

  return (
    <div className="pt-[80px] px-5 max-w-[1560px] mx-auto">
      {/* title */}
      <div className="">
        <div className="text-white font-medium mt-[68px] mb-[48px] text-[32px]">
          <span className="text-[#C778DD]">#</span>
          <span>small-projects</span>
        </div>
      </div>
      {/* body/cards */}
      <div className="cards flex justify-center flex-wrap gap-4">
        {projects.map((e) => {
          let { languages, title, body, webpage, scode, demo } = e;
          return (
            <>
              {/* card */}
              <div className="card max-w-[331px] w-full border border-[#ABB2BF]">
                {/* skills */}
                <div className=" border-b border-[#ABB2BF] p-2 text-[#ABB2BF]">
                  {languages.map((lang) => {
                    return <span>{lang} </span>;
                  })}
                </div>
                {/* description */}
                <div className="relative desc p-4">
                  <h2 className="text-white font-medium text-2xl">{title}</h2>
                  <p className="text-[#ABB2BF] py-4">{body}</p>
                  <div className="inline-grid grid-cols-3 gap-2">
                    <a href={webpage} target="_blank" rel="noreferrer">
                      <button className=" py-2 px-4 text-white border border-[#C778DD] hover:bg-[#C778DD33] duration-150">
                        Visit {'<'}~{'>'}
                      </button>
                    </a>
                    <a href={scode} target="_blank" rel="noreferrer">
                      <button className=" py-2 px-4 text-white border border-[#C778DD] hover:bg-[#C778DD33] duration-150">
                        GitHub {'<'}~{'>'}
                      </button>
                    </a>
                    <a href={demo} target="_blank" rel="noreferrer">
                      <button className=" py-2 px-4 text-white border border-[#C778DD] hover:bg-[#C778DD33] duration-150">
                        Demo {'<'}~{'>'}
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default SmallProjects;
