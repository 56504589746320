import ProjectCard from '../project_card/ProjectCard';
import first from './img/doc-pat.jpg';
import second from './img/pirate-ub.jpg';
import third from './img/movbuff.jpg';
import { NavLink } from 'react-router-dom';
import { SiMariadb, SiMongodb, SiExpress } from 'react-icons/si';
import {
  FaHtml5,
  FaCss3Alt,
  FaJsSquare,
  FaNode,
  FaPhp,
  FaReact,
} from 'react-icons/fa';

const Projects = () => {
  //date
  const projects = [
    {
      img: first,
      langs: [
        <FaHtml5 />,
        <FaCss3Alt />,
        <FaJsSquare />,
        <SiMongodb />,
        <SiExpress />,
        <FaReact />,
        <FaNode />,
      ],
      title: 'RunnerHealth™',
      subtitle: 'Senior Project 2023 - 2024',
      desc: 'An application that improves the quality of healthcare.',
      webpage: 'https://runnerhealth.xyz/',
      scode: 'https://github.com/Chidoskii/HealthApp-UI',
      demo: 'https://www.youtube.com/channel/UCzZSR2i9nK1OrxAcQ9xv9CQ',
    },
    {
      img: second,
      langs: [
        <FaHtml5 />,
        <FaCss3Alt />,
        <FaJsSquare />,
        <SiMariadb />,
        <FaPhp />,
      ],
      title: 'Usher Busser™',
      subtitle: 'Web Programming II - Spring 2024',
      desc: 'Movie theatre app for theatre employees and film aficionados.',
      webpage: 'https://usherbusser.xyz/',
      scode: 'https://github.com/Chidoskii/UsherBusser',
      demo: 'https://www.youtube.com/channel/UCzZSR2i9nK1OrxAcQ9xv9CQ',
    },
    {
      img: third,
      langs: [
        <FaHtml5 />,
        <FaCss3Alt />,
        <FaJsSquare />,
        <SiMariadb />,
        <FaPhp />,
      ],
      title: 'The Movie Buffs',
      subtitle: 'Software Engineering - Spring 2024',
      desc: 'A web app to rate and review films.',
      webpage: 'https://artemis.cs.csub.edu/~cokpara/MovieBuffs/',
      scode: 'https://github.com/Chidoskii/TheMovieBuffs',
      demo: 'https://www.youtube.com/watch?v=BtntLwrGN-I',
    },
  ];

  return (
    <>
      <div className="px-5 max-w-[1560px] mx-auto py-10">
        {/* top */}
        <div className="flex justify-between items-center gap-5">
          {/* left */}
          <div
            data-aos="fade-down"
            className="text-white w-2/3 font-medium text-[32px] flex items-center gap-2"
          >
            <div className="">
              <span className="text-[#C778DD]">#</span>projects
            </div>
            <div className="line w-2/3 h-px bg-[#C778DD]"></div>
          </div>
          {/* right */}
          <div data-aos="fade-up" className="text-white font-medium">
            <NavLink to="/projects">
              <span>View all ~~&gt;</span>
            </NavLink>
          </div>
        </div>
        {/* bottom */}
        <div className="flex flex-wrap justify-between gap-4 my-12">
          {projects.map(
            ({ img, langs, title, subtitle, desc, webpage, scode, demo }) => {
              return (
                <>
                  <ProjectCard
                    img={img}
                    langs={langs}
                    title={title}
                    subtitle={subtitle}
                    desc={desc}
                    webpage={webpage}
                    scode={scode}
                    demo={demo}
                  />
                </>
              );
            }
          )}
        </div>
      </div>
    </>
  );
};

export default Projects;
