import Projects from "../components/projects/Projects";
import Skills from "../components/skills_section/Skills";
import AboutSec from "../components/about_me_section/AboutMe";
import Contact from "../components/contact_section/Contact";
import About from "../components/first_section_about/About";
import YoutubePlayer from "../components/video_player/Youtube";
import ScrollToTop from "react-scroll-to-top";
import { FaArrowUp } from "react-icons/fa";

const HomePage = () => {
  return (
    <>
      <ScrollToTop
        id="scroll-to-top"
        title="back to top"
        smooth
        top={1500}
        component={<FaArrowUp id="arrow" size={"1.5em"} />}
      />
      <About />
      <Projects />
      <Skills />
      <AboutSec />
      <Contact />
    </>
  );
};
export default HomePage;
