import { NavLink } from "react-router-dom";

const AboutSec = () => {
  return (
    <>
      <div className="px-5 max-w-[1560px] mx-auto mt-20 py-10">
        {/* bottom */}
        <div className=" flex-wrap flex items-center justify-between">
          {/* left */}
          <div data-aos="fade-up" className="md:w-[48%] w-full">
            {/* title */}
            <div className=" mb-12">
              <div className=" text-white w-2/3 font-medium text-[32px] flex items-center gap-2">
                <div className="">
                  <span className="text-[#C778DD]">#</span>about-me
                </div>
                <div className="line w-1/3 h-px bg-[#C778DD]"></div>
              </div>
            </div>
            {/* disc */}
            <p className="text-[#ABB2BF] ">
              Hello, I'm Chidi Okpara!
              <br />
              <br />I am a goal driven, highly motivated frontend developer that
              is constantly learning new techniques to further improve my
              skillset. My interest in software and web development stems from
              my deep passion for sports. With all the advances made in modern
              technology, data collection in sports has never been more
              valuable. From determining the probability of where a baseball
              will land after being hit, to real-time data analysis during
              sports coverage; technology is changing the way sports are
              watched, played, and managed.
            </p>
            {/* button */}
            <div className="mt-7">
              <NavLink
                to="/about"
                className=" duration-150 hover:bg-[#C778DD33] border border-[#C778DD] px-4 py-2 text-white "
              >
                <button>Read more -{">"}</button>
              </NavLink>
            </div>
          </div>
          {/* right */}
          <div data-aos="fade-up" className=" mx-auto pt-5">
            <img className="mx-auto" src={require("./img/yup.png")} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutSec;
