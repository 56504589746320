import React from "react";

function AboutSkills() {
  const skills = [
    {
      title: "Languages",
      skills: [
        "JavaScript",
        "Java",
        "PHP",
        "SQL",
        "Ruby",
        "Python",
        "C#",
        "C++",
      ],
    },
    {
      title: "General",
      skills: [
        "Excellent communication",
        "Detail-oriented",
        "Leadership",
        "Self-driven",
      ],
    },
    {
      title: "Technical",
      skills: [
        "HTML/CSS",
        "Cloud computing",
        "Database management",
        "Troubleshooting",
        "Debugging",
        "RegEx",
      ],
    },
    {
      title: "Certificates",
      skills: ["Google IT Support Professional"],
    },
    {
      title: "Frameworks",
      skills: [
        "React",
        "Vue.js",
        "Angular",
        "Express",
        "Node.js",
        "ASP.NET",
        "Flask",
        "Django",
      ],
    },
    {
      title: "Tools",
      skills: ["VS-Code", "GitHub", "Render", "AWS", "Android Studio"],
    },
  ];
  return (
    <div className="px-5 max-w-[1560px] mx-auto pt-0 py-10">
      {/* Title */}
      <div className="mb-12 text-[32px] font-medium text-white">
        <span className="text-[#C778DD]">#</span>skills
      </div>
      {/* Cards */}
      <div className="flex justify-around gap-4 flex-wrap">
        {skills.map(({ title, skills }) => {
          return (
            <>
              <div className=" w-[90%] sm:w-[45%] md:w-[24%] lg:w-[15%] border border-[#ABB2BF]">
                <div className="text-white p-2">
                  <h2 className="font-semibold">{title}</h2>
                </div>
                <div className=" flex gap-3 border-t border-[#ABB2BF] flex-wrap p-2 text-[#ABB2BF]">
                  {skills.map((e) => {
                    return <span>{e}</span>;
                  })}
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default AboutSkills;
